import { IUserAuthPagination } from './api';
import { ICustomer } from './customer';

// from user-auth-service shared/constants.py
export enum UserRole {
  VISITOR = 'visitor',
  CONTRIBUTOR = 'contributor',
  REPORTER = 'reporter',   // AMP - a user with access only to the "customer reports" pages
  USER = 'user',         // AMP - a view only user in the platform
  ADMIN = 'admin',       // AMP - customers in the platform who can take actions (create/edit etc.)
  OWNER = 'owner',
  SADMIN = 'sadmin',
  UADMIN = 'uadmin',
}

export const UserRoleHierarchy: Record<UserRole, number> = {
  visitor: 1,
  contributor: 2,
  reporter: 3,
  user: 4,
  admin: 5,
  owner: 6,
  sadmin: 7,
  uadmin: 8,
}

export enum UserStatus {
  UNVERIFIED = 'unverified',
  INVITED = 'invited',
  ACTIVE = 'active',
  VERIFIED = 'verified',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum UserPropertyTypes {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  JSON = 'JSON',
  BOOLEAN = 'BOOLEAN',
}

export enum UserPropertyNamespace {
  SETTINGS = 'SETTINGS',
  GATES = 'GATES',
  PRIVATE = 'PRIVATE',
  NONE = 'NONE',
}



export interface IUserAttribute {
  name: string
  namespace: UserPropertyNamespace
  type: UserPropertyTypes
  value: string | boolean | number | Record<string, string | number | boolean | string[]>
}

export enum UserProperty {
  FAVE_CUST_IDS = "starred_customer_ids",
  MEASUREMENT_PREF = "measurement_display_preference",
}



export interface IUser {
  id: string
  customer_id: string
  email: string
  name: string
  status: UserStatus
  role: UserRole
  created_at: string
  attributes: IUserAttribute[]
}


export interface IFetchBootstrapResponse {
  user: IUser
  ws_token: string
  opcos: ICustomer[]
  customer: ICustomer
}

export interface IFetchUserPageResponse {
  data: IUser[]
  meta: {
    pagination: IUserAuthPagination
  }
}


export const isSingularityAdmin = (user: IUser) => {
  return user.role === UserRole.SADMIN || user.role === UserRole.UADMIN;
}

export const isAtLeastAdmin = (user: IUser) => {
  return isSingularityAdmin(user) || user.role === UserRole.OWNER || user.role === UserRole.ADMIN;
}

export enum MeasurementDisplayPreference {
  IMPERIAL = 'Imperial (US)',
  METRIC = 'Metric System',
}

